import DateFnsUtils from "@date-io/date-fns";

import { FormControl, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import React from "react";

import { filterTypes } from "../constants";
import { useFilter } from "../hooks";
import { IFilterProps } from "../interfaces";
import { useFilterStyles } from "../styles";
import {
  ButtonStyle,
  Container,
  DateIcon,
  Label,
  StyledKeyboardDatePicker,
} from "../styles/Filter";

const Filter = ({
  selectedTab,
  pin,
  disableSearchButton,
  setPin,
  birthDate,
  setCustomerDetails,
  setBirthDate,
  setIsLoading,
}: IFilterProps): React.ReactElement => {
  const { translate } = useTranslation();
  const classes = useFilterStyles();
  const { handleSearch } = useFilter({
    selectedTab,
    setIsLoading,
    setCustomerDetails,
  });

  return (
    <Container>
      <FormControl className={classes.inputControl}>
        <Label>{translate("request_edu_pin")}</Label>
        <TextField
          onChange={(event) => {
            setPin(event.target.value.toUpperCase());
          }}
          value={pin}
          variant='outlined'
          size='small'
        />
      </FormControl>
      {selectedTab === filterTypes[0].name && (
        <FormControl className={classes.inputControl}>
          <Label>{translate("request_edu_birthday")}</Label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <StyledKeyboardDatePicker
              className={classes.root}
              size='small'
              variant='inline'
              format='dd-MM-yyyy'
              margin='normal'
              value={birthDate}
              onChange={(fulldate) => setBirthDate(fulldate)}
              keyboardIcon={<DateIcon />}
              InputLabelProps={{
                shrink: true,
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  border: "1px solid #ced4da",
                  borderRadius: 4,
                  padding: "4px 15px",
                },
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      )}
      <FormControl className={classes.buttonControl}>
        <ButtonStyle
          disabled={disableSearchButton}
          onClick={() => handleSearch(pin, birthDate)}
          buttonType={buttonTypes.success}>
          {translate("request_edu_search")}
        </ButtonStyle>
      </FormControl>
    </Container>
  );
};

export default Filter;
