import { styled } from "@material-ui/core";
import Button from "Components/Buttons/Button";

export const Container = styled("div")({
  backgroundColor: "white",
  display: "flex",
  alignItems: "end",
  marginTop: 25,
});

export const ConfirmButtonStyle = styled(Button)({
  background: "rgba(0, 178, 150, 0.1)",
  color: "#00B287",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  cursor: "pointer",
  width: "80%",
  padding: "8px 16px",
  margin: "0 auto",
  "&.disabled": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  "&.disable": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
});

export const CancelButtonStyle = styled(Button)({
  background: "rgba(255, 51, 51, 0.1)",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  cursor: "pointer",
  width: "80%",
  padding: "8px 16px",
  margin: "0 auto",
  "&.disabled": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  "&.disable": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
});
