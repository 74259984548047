/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable eqeqeq */
import { fetchCustomerData, getFormFields } from "Api/crm";
import { getRequestById } from "Api/request";
import menuTypes from "Assets/constants/menuTypes";

import { RequestContextStore } from "Components/Layout/AppLayout";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { default as CrmActions } from "Store/Actions/Crm";
import { defaultStates as defaultCrmStates } from "Store/Reducers/crmReducer";

import { getRandomInt, validatePhoneNumber } from "Utils/Utils";

import { parseDateFromString } from "Utils/UtilsFunc";

import { customerTypes } from "../constants/customerTypes";
import menuItems from "../constants/menuItems";
import {
  fillDefaultFieldWithCustomerFieldsValues,
  getFieldByfieldType,
  getFieldNameRow,
  setOptionsByDictionaryId,
} from "../CrmForm";

export const useCrm = (): any => {
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const callState = useSelector((state: RootStateOrAny) => state.callReducer);
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );
  const eventSourceState = useSelector(
    (state: RootStateOrAny) => state.eventSourceReducer,
  );

  const crm = new CrmActions(useDispatch());

  const [isLoading, setIsLoading] = useState(false);

  const fullNameFB =
    chatBoxState.conversations[chatBoxState.activeConversationId] &&
    chatBoxState.conversations[chatBoxState.activeConversationId].type === "FB"
      ? chatBoxState.conversations[chatBoxState.activeConversationId].fullName
      : null;
  const requestContextStore: any = useContext(RequestContextStore);
  const crmState = requestContextStore.getCurrentStateFromTotalState(
    totalCrmState,
    defaultCrmStates,
  );

  const getCustomerData = async (
    channelType,
    identifier,
    defaultFields,
  ): Promise<any> => {
    const number =
      identifier && identifier.charAt(0) === "+"
        ? "%2B" + identifier.substring(1)
        : identifier;

    const result = number
      ? number === "spy-call"
        ? await fetchCustomerData(
            channelType,
            eventSourceState.spycallPhoneNumber,
          )
        : await fetchCustomerData(channelType, number)
      : null;
    const fetchRequestById: any =
      callState.activeCallId !== 0 && channelType === channelType?.SIPPHONE
        ? await getRequestById(callState.activeCallId)
        : null;

    const setDefaultFieldsForFB = defaultFields?.map((item) => {
      if (fullNameFB != null) {
        const fullName: any = fullNameFB.split(" ");
        if (item.fieldName == "Name")
          return { ...item, fieldValue: fullName[0] };
        else if (item.fieldName == "Last Name")
          return { ...item, fieldValue: fullName[1] };
      }
      return item;
    });

    if (result) {
      const updatedFieldValueList = fillDefaultFieldWithCustomerFieldsValues(
        defaultFields,
        callState.activeCallId !== 0 && channelType === channelType.SIPPHONE
          ? fetchRequestById?.customerData?.fieldValueList
          : result.fields,
      );
      crm.setCustomerData(
        requestState.menuType,
        requestContextStore.getRequestId(),
        result.identifiers,
        result.id,
        updatedFieldValueList,
        result.photo,
        result.customers,
      );
    } else {
      const currentConversation =
        chatBoxState.conversations[chatBoxState.activeConversationId];
      const photo = currentConversation
        ? currentConversation.base64Picture
        : null;

      crm.setCustomerData(
        requestState.menuType,
        requestContextStore.getRequestId(),
        [
          {
            identifier:
              channelType === Object.keys(menuItems)[0] ? identifier : null,
            channelType: Object.keys(menuItems)[0],
          },
          {
            identifier:
              channelType === Object.keys(menuItems)[1] ? identifier : null,
            channelType: Object.keys(menuItems)[1],
          },
          {
            identifier:
              channelType === Object.keys(menuItems)[2] ? identifier : null,
            channelType: Object.keys(menuItems)[2],
          },
          {
            identifier:
              channelType === Object.keys(menuItems)[3] ? identifier : null,
            channelType: Object.keys(menuItems)[3],
          },
          {
            identifier:
              channelType === Object.keys(menuItems)[4] ? identifier : null,
            channelType: Object.keys(menuItems)[4],
          },
          {
            identifier:
              channelType === Object.keys(menuItems)[5] ? identifier : null,
            channelType: Object.keys(menuItems)[5],
          },
        ],
        null,
        setDefaultFieldsForFB,
        photo,
        [],
      );
    }
  };

  const getCrmFieldNameRow = (
    field: any,
    fieldType: customerTypes,
  ): React.ReactElement => {
    const shouldCheckValidation =
      crmState.checkValidationGroup === field.customerType ||
      crmState.checkValidationGroup === customerTypes.ALL
        ? true
        : false;

    return getFieldNameRow({
      shouldCheckValidation,
      field,
      fieldType,
      setFieldValue: (value, id, fieldName) => {
        crm.setFieldValue(
          requestState.menuType,
          requestContextStore.getRequestId(),
          id,
          fieldName,
          value,
        );
      },
      setFieldPlaceHolder: (id, value) => {
        crm.setFieldPlaceHolder(
          requestState.menuType,
          requestContextStore.getRequestId(),
          id,
          value,
        );
      },
      onSelectFocus: () => {
        const options = field.options ? field.options : [];
        if (Object.keys(options).length === 0) {
          setOptionsByDictionaryId(
            field.dictionaryId,
            (dictionaryId, options) => {
              crm.setOptionsByDictionaryId(
                requestState.menuType,
                requestContextStore.getRequestId(),
                dictionaryId,
                options,
              );
            },
          );
        }
      },
      handleKeyDown: field.handleKeyDown ? field.handleKeyDown : () => {},
    });
  };

  const setCustomerInfo = (): void => {
    const updateFormFields = crmState.formFields.map((field) => {
      switch (field.fieldName) {
        case "CIF":
          field.fieldValue = totalCrmState.customerCif ?? field.fieldValue;
          return field;
        case "Card Number":
          field.fieldValue = totalCrmState.accountNumber ?? field.fieldValue;
          return field;
        default:
          return field;
      }
    });

    crm.setCustomerData(
      requestState.menuType,
      requestContextStore.getRequestId(),
      crmState.identifiers,
      crmState.customerId,
      updateFormFields,
      crmState.profileImage,
      crmState.companies,
    );
  };

  useEffect(() => {
    const updateFormFields = crmState.formFields.map((field) => {
      switch (field.fieldName) {
        case "Name":
          field.fieldValue = totalCrmState.eduIntegration?.firstName;
          return field;
        case "Last Name":
          field.fieldValue = totalCrmState.eduIntegration?.lastName;
          return field;
        case "Father Name":
          field.fieldValue = totalCrmState.eduIntegration?.fatherName;
          return field;
        case "Birthdate":
          field.fieldValue = totalCrmState.eduIntegration?.birthDate;
          return field;
        case "FIN":
          field.fieldValue = totalCrmState.eduIntegration?.pin;
          return field;
        default:
          return field;
      }
    });
    crm.setCustomerData(
      requestState.menuType,
      requestContextStore.getRequestId(),
      crmState.identifiers,
      crmState.customerId,
      updateFormFields,
      crmState.profileImage,
      crmState.companies,
    );
    setIsLoading(false);
  }, [totalCrmState.eduConfirmButton]);

  const splitTextIntoArray = async (dataArray): Promise<void> => {
    setIsLoading(true);
    const value = dataArray.pop();
    const valueParts = value.match(/[\d.]+|[A-Z]+/g);
    dataArray.push(...valueParts);

    const updateFormFields = crmState.formFields.map((field) => {
      switch (field.fieldName) {
        case "Əməliyyat nömrəsi":
          field.fieldValue = dataArray[0] ?? "";
          return field;
        case "Ödəmə tarixi":
          field.fieldValue = dataArray[1]
            ? parseDateFromString(dataArray[1])
            : "";
          return field;
        case "Ödəmə saatı":
          field.fieldValue = dataArray[2] ?? "";
          return field;
        case "ÖQM növü":
          field.fieldValue = dataArray[3] ?? "";
          return field;
        case "ÖQM kodu":
          field.fieldValue = dataArray[4] ?? "";
          return field;
        case "Məbləğ":
          field.fieldValue = dataArray[5] ?? "";
          return field;
        case "Valyuta":
          field.fieldValue = dataArray[6] ?? "";
          return field;
        default:
          return field;
      }
    });
    crm.setCustomerData(
      requestState.menuType,
      requestContextStore.getRequestId(),
      crmState.identifiers,
      crmState.customerId,
      updateFormFields,
      crmState.profileImage,
      crmState.companies,
    );
    setIsLoading(false);
  };

  const getCrmField = (
    field: any,
    fieldType: customerTypes,
  ): React.ReactElement => {
    const shouldCheckValidation =
      crmState.checkValidationGroup === field.customerType ||
      crmState.checkValidationGroup === customerTypes.ALL
        ? true
        : false;

    return getFieldByfieldType({
      shouldCheckValidation,
      field,
      fieldType,
      setFieldValue: (value, id, fieldName) => {
        const dataArray = typeof value === "string" ? value.split(/\s+/) : [];
        if (dataArray.length > 5 && fieldName === "Əməliyyat nömrəsi") {
          splitTextIntoArray(dataArray);
        } else {
          crm.setFieldValue(
            requestState.menuType,
            requestContextStore.getRequestId(),
            id,
            fieldName,
            value,
          );
        }
      },
      setFieldPlaceHolder: (id, value) => {
        crm.setFieldPlaceHolder(
          requestState.menuType,
          requestContextStore.getRequestId(),
          id,
          value,
        );
      },
      onSelectFocus: () => {
        const options = field.options ? field.options : [];
        if (Object.keys(options).length === 0) {
          setOptionsByDictionaryId(
            field.dictionaryId,
            (dictionaryId, options) => {
              crm.setOptionsByDictionaryId(
                requestState.menuType,
                requestContextStore.getRequestId(),
                dictionaryId,
                options,
              );
            },
          );
        }
      },
      handleKeyDown: field.handleKeyDown ? field.handleKeyDown : () => {},
    });
  };

  const updateAndReturnDefaultFields = async (): Promise<any> => {
    const defaultFields = await getFormFields();
    crm.setDefaultFields(defaultFields);
    return defaultFields;
  };

  useEffect(() => {
    (async function anyNameFunction() {
      const defaultFields = totalCrmState.defaultFields
        ? JSON.parse(JSON.stringify(totalCrmState.defaultFields))
        : await updateAndReturnDefaultFields();

      if (requestState.menuType == menuTypes.CHAT) {
        const activeConversation =
          chatBoxState.conversations[chatBoxState.activeConversationId];

        const identifierType = activeConversation
          ? activeConversation.type
          : null;
        const identifierNumber = activeConversation
          ? activeConversation.channelId
          : null;
        const isConversationId = activeConversation
          ? activeConversation.conversationId
          : null;

        setIsLoading(true);
        !Object.prototype.hasOwnProperty.call(
          totalCrmState[requestState.menuType],
          isConversationId,
        ) &&
          (await getCustomerData(
            identifierType,
            identifierNumber,
            defaultFields,
          ));
        setIsLoading(false);
      } else if (requestState.menuType == menuTypes.CALL) {
        if (softPhoneState.isACWCallOver && callState.activeCallId === 0)
          return;

        let numberToSearch = null;

        if (typeof callState.activeCallId == "string")
          numberToSearch = callState.calls[callState.activeCallId].number;
        else if (callState.activeCallId == 0) {
          numberToSearch = softPhoneState.lines[softPhoneState.currentLine]
            ? softPhoneState.lines[softPhoneState.currentLine].callerName
            : null;
        }
        const customerId =
          totalCrmState[requestState.menuType] &&
          totalCrmState[requestState.menuType][softPhoneState.currentLine] &&
          totalCrmState[requestState.menuType][softPhoneState.currentLine]
            .customerId;
        setIsLoading(true);

        !customerId &&
          (await getCustomerData(
            "SIPPHONE",
            validatePhoneNumber(numberToSearch),
            defaultFields,
          ));
        setIsLoading(false);
      }
    })();
  }, [
    chatBoxState.activeConversationId,
    callState.activeCallId,
    softPhoneState.currentLine,
    softPhoneState.lines[softPhoneState.currentLine]?.callerName,
  ]);

  useEffect(() => {
    (totalCrmState.customerCif || totalCrmState.accountNumber) &&
      setCustomerInfo();
  }, [totalCrmState.customerCif, totalCrmState.accountNumber]);

  return {
    crmState,
    crm,
    requestState,
    requestContextStore,
    isLoading,
    getCrmField,
    getCrmFieldNameRow,
  };
};

useCrm.displayName = "useCrm";

export const getCustomField = (
  customerType: customerTypes,
  customParams: any = {},
): object => {
  return {
    activeColumn: true,
    custom: true,
    customerType,
    dictionaryId: null,
    editable: true,
    fieldName: "",
    fieldType: { id: 1, typeName: "TEXT", errorText: null, regex: "" },
    errorText: null,
    id: getRandomInt(9999, 1000000099999),
    regex: "",
    typeName: "TEXT",
    includesInCapture: false,
    mandatory: false,
    multiple: false,
    optionsCanBeAdded: false,
    ...customParams,
  };
};
