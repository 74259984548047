import { FormControl, TextField } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import React from "react";

import { RootStateOrAny, useSelector } from "react-redux";
import { formatBirthDate } from "Utils/Utils";

import { filterTypes } from "../constants";
import { CustomerDetailsProps } from "../interfaces";
import { useCustomerDetailsStyles } from "../styles";
import { Container, Label } from "../styles/CustomerDetails";

const CustomerDetails = ({
  selectedTab,
  customerDetails,
}: CustomerDetailsProps): React.ReactElement => {
  const classes = useCustomerDetailsStyles();
  const { translate } = useTranslation();
  const crmState = useSelector((state: RootStateOrAny) => state.crmReducer);
  const { fatherName, firstName, lastName, birthDate } = customerDetails;

  return (
    <Container>
      <FormControl className={classes.inputControl}>
        <Label>{translate("request_edu_first_name")}</Label>
        <TextField
          value={
            firstName
              ? crmState.eduConfirmButton
                ? firstName
                : firstName.slice(0, 2) + "**"
              : ""
          }
          variant='outlined'
          size='small'
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
      <FormControl className={classes.inputControl}>
        <Label>{translate("request_edu_last_name")}</Label>
        <TextField
          value={
            lastName
              ? crmState.eduConfirmButton
                ? lastName
                : lastName.slice(0, 2) + "**"
              : ""
          }
          variant='outlined'
          size='small'
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
      {selectedTab === filterTypes[0].name && (
        <FormControl className={classes.inputControl}>
          <Label>{translate("request_edu_father_name")}</Label>
          <TextField
            value={
              fatherName
                ? crmState.eduConfirmButton
                  ? fatherName
                  : fatherName.slice(0, 2) + "**"
                : ""
            }
            variant='outlined'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      )}
      <FormControl className={classes.inputControl}>
        <Label>{translate("request_edu_birth_date")}</Label>
        <TextField
          value={
            birthDate
              ? crmState.eduConfirmButton
                ? formatBirthDate(birthDate)
                : `** ** ${formatBirthDate(birthDate).slice(-4)}`
              : ""
          }
          variant='outlined'
          size='small'
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Container>
  );
};

export default CustomerDetails;
