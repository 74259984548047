import { styled } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DateRangeOutlinedIcon } from "Assets/icons";
import Button from "Components/Buttons/Button";

export const Container = styled("div")({
  backgroundColor: "white",
  display: "flex",
  alignItems: "end",
  marginTop: 25,
});

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)({
  width: "100%",
  marginBottom: 0,
  marginTop: 0,
  "& input:focus": {
    boxShadow: "0 0 1px 1px white !important",
  },
});

export const Label = styled("span")({
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginBottom: 10,
  marginTop: 10,
});

export const DateIcon = styled(DateRangeOutlinedIcon)({
  color: "#9F9DAD",
  fontSize: 20,
  lineHeight: 17,
  fontWeight: 600,
});

export const ButtonStyle = styled(Button)({
  background: "rgba(0, 178, 150, 0.1)",
  color: "#00B287",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  cursor: "pointer",
  width: "80%",
  padding: "8px 16px",
  margin: "0 auto",
  "&.disabled": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  "&.disable": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
});
