import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import React from "react";

import { filterTypes } from "../constants";
import { ITabsProps } from "../interfaces";
import { Container, FormLabel } from "../styles/Tabs";

const Tabs = ({
  selectedTab,
  setSelectedTab,
}: ITabsProps): React.ReactElement => {
  const { translate } = useTranslation();
  return (
    <Container>
      <FormLabel>{translate("request_edu_document_file")}</FormLabel>
      <RadioGroup
        row
        aria-label='position'
        name='position'
        defaultValue={selectedTab}
        onChange={(e) => setSelectedTab(e.target.value)}>
        {filterTypes.map((type, index) => (
          <FormControlLabel
            key={index}
            value={type.name}
            control={<Radio color='primary' />}
            label={translate(type.alias)}
            style={{ marginRight: "46px" }}
          />
        ))}
      </RadioGroup>
    </Container>
  );
};

export default Tabs;
