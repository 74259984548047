import {
  getCustomDetailsByIdCard,
  getCustomDetailsByResidencePermit,
} from "Api/iamasIntegration";

import { useDispatch } from "react-redux";
import { default as CrmActions } from "Store/Actions/Crm";

import { formatDateObjectToString } from "Utils/Utils";

import { filterTypes } from "../constants";

import { IUseFilter, useFilterProps } from "../interfaces";

export const useFilter = ({
  selectedTab,
  setIsLoading,
  setCustomerDetails,
}: useFilterProps): IUseFilter => {
  const crm = new CrmActions(useDispatch());

  const handleSearch = async (pin, birthday): Promise<void> => {
    setIsLoading(true);
    if (selectedTab === filterTypes[0].name) {
      const response = await getCustomDetailsByIdCard(
        pin,
        formatDateObjectToString(birthday),
      );
      if (response.status === 200) {
        const { data } = response;
        crm.setEduIntegration({
          firstName: data?.firstName,
          lastName: data?.lastName,
          fatherName: data?.fatherName,
          birthDate: data?.birthDate,
          pin,
        });
        setCustomerDetails({
          firstName: data?.firstName,
          lastName: data?.lastName,
          fatherName: data?.fatherName,
          birthDate: data?.birthDate,
        });
      }
    } else {
      const response = await getCustomDetailsByResidencePermit(pin);
      if (response.status === 200) {
        const { data } = response;
        crm.setEduIntegration({
          firstName: data?.firstName,
          lastName: data?.lastName,
          fatherName: data?.fatherName,
          birthDate: data?.birthDate,
          pin,
        });
        setCustomerDetails({
          firstName: data?.firstName,
          lastName: data?.lastName,
          fatherName: data?.fatherName,
          birthDate: data?.birthDate,
        });
      }
    }
    crm.setEduConfirmButton(false);
    setIsLoading(false);
  };

  return {
    handleSearch,
  };
};

useFilter.displayName = "useFilter";
