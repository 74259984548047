import { styled } from "@material-ui/core";

export const Container = styled("div")({
  backgroundColor: "white",
});

export const FormLabel = styled("div")({
  marginBottom: "10px",
  fontSize: "15px",
});
