import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Notification from "Notifications/Notification";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { default as CrmActions } from "Store/Actions/Crm";

import { filterTypes, initialCustomerDetails } from "../constants";
import { ICustomField, IUseEduIntegration } from "../interfaces";

export const useEduIntegration = (): IUseEduIntegration => {
  const { translate } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(filterTypes[0].name);
  const [pin, setPin] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date | null | undefined>(null);
  const [customerDetails, setCustomerDetails] = useState<ICustomField>(
    initialCustomerDetails,
  );
  const [isLoading, setIsLoading] = useState(false);

  const disableSearchButton =
    !pin || (selectedTab === filterTypes[0].name && !birthDate);

  const crm = new CrmActions(useDispatch());

  const handleCancelButton = (): void => {
    setPin("");
    setBirthDate(null);
    setCustomerDetails(initialCustomerDetails);
    crm.setEduConfirmButton(false);
  };

  const handleConfirmButton = (): void => {
    crm.setEduConfirmButton(true);
    Notification.success(translate("successfully_saved"));
  };

  useEffect(() => {
    handleCancelButton();
  }, [selectedTab]);

  return {
    selectedTab,
    pin,
    birthDate,
    isLoading,
    disableSearchButton,
    customerDetails,
    setCustomerDetails,
    setIsLoading,
    setPin,
    setSelectedTab,
    setBirthDate,
    handleCancelButton,
    handleConfirmButton,
  };
};

useEduIntegration.displayName = "useEduIntegration";
