import { styled } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { availablePages, permissions } from "Config/config";
import React from "react";

import { isPagePermitted } from "Utils/Utils";

import { INavigationBarItems } from "./interfaces";
import NavigationBarItem from "./NavigationBarItem";

const NavigationBar = (): React.ReactElement => {
  const { translate } = useTranslation();
  const navigationItems: Array<INavigationBarItems> = [
    {
      url: availablePages.index,
      label: translate("navbar_dashboard"),
      popup: false,
      isPermitted: true,
    },
    {
      label: translate("navbar_menu"),
      popup: true,
      isPermitted: true,
    },
    {
      url: availablePages.dashboards,
      label: translate("navbar_monitoring"),
      popup: false,
      isPermitted: isPagePermitted(permissions.main_menu_monitoring),
    },
  ];

  return (
    <Container>
      {navigationItems &&
        navigationItems.map((route, index) => {
          if (route.isPermitted)
            return (
              <NavigationBarItem
                key={index}
                url={route.url}
                label={route.label}
                popup={route.popup}
              />
            );
          return null;
        })}
    </Container>
  );
};

export default NavigationBar;

NavigationBar.displayName = "MobileNavigationContainer";

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  "& div:not(:last-child)": {
    marginRight: 26,
  },
  justifyContent: "center",
  padding: "10px 0 0 0",
});
