import { FormControl } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import React from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import { ActionsButtonProps } from "../interfaces";
import { useActionsButtonStyles } from "../styles";
import {
  ConfirmButtonStyle,
  CancelButtonStyle,
  Container,
} from "../styles/ActionsButton";

const ActionsButton = ({
  customerDetails,
  handleCancelButton,
  handleConfirmButton,
}: ActionsButtonProps): React.ReactElement => {
  const { translate } = useTranslation();
  const classes = useActionsButtonStyles();

  const crmState = useSelector((state: RootStateOrAny) => state.crmReducer);

  const disableButton = !customerDetails.firstName || crmState.eduConfirmButton;

  return (
    <Container>
      <FormControl className={classes.buttonControl}>
        <ConfirmButtonStyle
          disabled={disableButton}
          onClick={handleConfirmButton}
          buttonType={buttonTypes.success}>
          {translate("request_edu_confirm_button")}
        </ConfirmButtonStyle>
      </FormControl>
      <FormControl className={classes.buttonControl}>
        <CancelButtonStyle
          onClick={handleCancelButton}
          buttonType={buttonTypes.danger}>
          {translate("request_edu_cancel_button")}
        </CancelButtonStyle>
      </FormControl>
    </Container>
  );
};

export default ActionsButton;
