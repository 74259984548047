import { makeStyles } from "@material-ui/core";

export const useFilterStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        marginBottom: 3,
      },
      "& .MuiButtonBase-root": {
        marginRight: 0,
      },
    },
  },
  inputControl: {
    width: 160,
    height: 102,
    marginRight: 50,
  },
  buttonControl: {
    height: 87,
    width: 100,
    display: "flex",
    justifyContent: "center",
  },
});

export const useCustomerDetailsStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        marginBottom: 3,
      },
      "& .MuiButtonBase-root": {
        marginRight: 0,
      },
    },
  },
  inputControl: {
    width: 160,
    height: 102,
    marginRight: 50,
  },
});

export const useActionsButtonStyles = makeStyles({
  buttonControl: {
    height: 80,
    width: 130,
    display: "flex",
    justifyContent: "center",
  },
});
