/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */

import femaleAgent from "Components/Layout/NavBar/images/femaleAgent.png";
import maleAgent from "Components/Layout/NavBar/images/maleAgent.png";
import moment from "moment";
import FileTypes from "Pages/FaqGenerator/fileTypes";
import React, { useRef } from "react";

const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";
const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = () => {
  return moment(this).format();
};

export const removeByValueOfArray = (arr, value): Array<any> => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const getRandomInt = (min = 10000000, max = 9999999999): number => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const getCountdownFromDateTime = (dateTime: string): string => {
  const end = new Date(dateTime).getTime();
  return new Date(Date.now() - end).toISOString().slice(11, 19);
};

export const isPagePermitted = (permission): boolean => {
  const userPermissions = localStorage.getItem("authorities")
    ? JSON.parse(localStorage.getItem("authorities"))
    : {};

  return !!userPermissions[permission];
};

export const permit = (Component, keyWord = null, returnValue = null): any => {
  const userPermissions = localStorage.getItem("authorities")
    ? JSON.parse(localStorage.getItem("authorities"))
    : {};

  if (keyWord === null || userPermissions[keyWord]) return Component;
  else return returnValue;
};

export const useCancellablePromises = (): any => {
  const pendingPromises = useRef([]);

  const appendPendingPromise = (promise): Array<any> =>
    (pendingPromises.current = [...pendingPromises.current, promise]);

  const removePendingPromise = (promise): Array<any> =>
    (pendingPromises.current = pendingPromises.current.filter(
      (p) => p !== promise,
    ));

  const clearPendingPromises = (): Array<any> =>
    pendingPromises.current.map((p) => p.cancel());

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};

export const cancellablePromise = (
  promise,
): { promise: Promise<any>; cancel: any } => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

export const delay = (n): Promise<any> =>
  new Promise((resolve) => setTimeout(resolve, n));

export const useClickPreventionOnDoubleClick = (
  onClick,
  onDoubleClick,
): Array<any> => {
  const api = useCancellablePromises();

  const handleClick = (): Promise<any> => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick();
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = (): void => {
    api.clearPendingPromises();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};

export const camelCaseToWord = (string): string => {
  return string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
};

export const convertHexToRGBA = (hexCode, opacity): string => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex += hex;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const range = (size: number, startAt = 0): ReadonlyArray<number> => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const capitalize = (s): string => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const checkVisibility = (function () {
  let stateKey;
  return function () {
    return !document[stateKey];
  };
})();

export const serializeObjectToUrlQueryParams = (obj: any): string => {
  let str = "";
  for (const key in obj) {
    if (str != "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str;
};

export const formatJavaLocalDateTimetoJsDateTime = (datetime): string => {
  if (datetime)
    return (
      datetime.toJSON().slice(0, 10) + " " + datetime.toJSON().slice(11, 19)
    );
  return "";
};

export const formatDate = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("DD-MM-YYYY HH:mm:ss");
};

export const formatDateTwo = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("YYYY-MM-DD HH:mm");
};

export const formatDateThree = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("YYYY-MM-DD HH:mm:ss");
};

export const formatDateFour = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("DD.MM.YYYY HH:mm");
};

export const formatDateFive = (dateString): string => {
  if (typeof dateString === "string" && dateString.includes("T")) {
    dateString = dateString.replace("T", " ");
    return dateString;
  } else {
    const [day, month, rest] = dateString.split("-");
    const [year, time] = rest.split(" ");
    return `${year}-${month}-${day} ${time}`;
  }
};

export const formatDateSix = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("YYYY-MM-DDTHH:mm:ss.0+0000");
};

export const formatDateDay = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("DD-MM-YYYY");
};

export const formatDateDayTwo = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("DD/MM/YYYY");
};
export const formatDateForExcel = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("MM/DD/YYYY");
};
export const formatDateForExcelDateTwo = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("MM/DD/YYYY HH:mm");
};
export const formatDatetoHoursAndSecs = (date): string => {
  const tempDate = new Date(date);
  return moment(tempDate).format("HH:mm");
};

export const roundOffToHour = (date): Date => {
  date.setHours(date.getHours() + 1);
  date.setMinutes(0, 0, 0);

  return date;
};

export const conversion = (time): string => {
  let fullTime = "";
  const minute = Math.floor(time / 60);
  fullTime += minute.toString().length === 1 ? "0" + minute : minute;
  const second = Math.round(time % 60);
  fullTime += `:${second.toString().length === 1 ? "0" + second : second}`;
  return fullTime;
};

export const datesAreOnSameDay = (first, second): boolean =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const isToday = (date): boolean => {
  const today = new Date();
  date = new Date(date);
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const validatePhoneNumber = (num: string): string => {
  const number = num == null ? "" : num;
  switch (number.length) {
    case 7:
      return "99412" + number;
    case 9:
      return "994" + number;
    case 10:
      if (number.startsWith("0")) return "994" + number.slice(1);
      break;
    case 12:
      if (number.startsWith("00994")) return number.slice(2);
      break;
    case 13:
      if (number.startsWith("9940")) return "994" + number.slice(4);
      break;
    default:
      return number;
  }

  return number;
};

export const validatePhoneNumbers = (num: string): string => {
  const number = num == null ? "" : num;

  switch (number.length) {
    case 14:
      if (number.startsWith("99412")) return "994" + number.slice(5);
      break;
    case 11:
      if (number.startsWith("00")) return "994" + number.slice(2);
      break;
    default:
      return number;
  }

  return number;
};

// Faq Generator Questions sort in FaqGeneratorQuestions.js

export const array_move = (array, old_index, new_index): Array<any> => {
  const arr = [...array];

  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

  return arr;
};

export const serialize = function (obj): string {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + "=" + obj[p]);
    }
  return str.join("&");
};

export const handleDashboardItemId = function (id: number): string {
  const str = "&dashboardItemId=" + id;
  return id ? str : "";
};

export const addHours = function (date: Date, h: number): Date {
  date.setTime(date.getTime() + h * 60 * 60 * 1000);
  return date;
};

export const addMinutes = function (date: Date, h: number): Date {
  date.setTime(date.getTime() + h * 60 * 1000);
  return date;
};

export const ceilToHalfAnHour = (hours: number, minutes: number): Date => {
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(0);
  if (minutes > 30) {
    date = addHours(date, 1);
  } else {
    date = addMinutes(date, 30);
  }
  return date;
};

export const toTimeFormat = function (
  sec: string | number,
  excel?: boolean,
  hasSeconds = true,
): any {
  if (!sec)
    return excel ? (
      "00:00:00 00"
    ) : (
      <>
        00:00:00<span style={{ fontSize: "8px" }}>00</span>
      </>
    );

  let minus = "";
  const secValue = Number(sec);
  if (secValue < 0) {
    minus = "-";
    sec = Math.abs(secValue);
  }
  const timeInSeconds = {
    minute: 60,
    hour: 60 * 60,
    day: 24 * 60 * 60,
    month: 30.4 * 24 * 60 * 60,
    year: 365 * 24 * 60 * 60,
  };
  let milliseconds = "00";
  const tempMilliseconds = sec.toString().split(".");
  milliseconds =
    tempMilliseconds[1] === undefined
      ? "00"
      : tempMilliseconds[1].length === 1
      ? tempMilliseconds[1].substring(0, 2) + "0"
      : tempMilliseconds[1].substring(0, 2);

  let sec_num: any = null;
  if (typeof sec !== "string") sec = sec.toString();
  sec_num = parseInt(sec, 10); // don't forget the second param
  let years: string | number = Math.floor(sec_num / timeInSeconds.year);
  let months: string | number = Math.floor(
    (sec_num - years * timeInSeconds.year) / timeInSeconds.month,
  );
  let days: string | number = Math.floor(
    (sec_num - years * timeInSeconds.year - months * timeInSeconds.month) /
      timeInSeconds.day,
  );
  let hours: string | number = Math.floor(
    (sec_num -
      years * timeInSeconds.year -
      months * timeInSeconds.month -
      days * timeInSeconds.day) /
      timeInSeconds.hour,
  );
  let minutes: string | number = Math.floor(
    (sec_num -
      years * timeInSeconds.year -
      months * timeInSeconds.month -
      days * timeInSeconds.day -
      hours * timeInSeconds.hour) /
      timeInSeconds.minute,
  );
  let seconds: string | number =
    sec_num -
    years * timeInSeconds.year -
    months * timeInSeconds.month -
    days * timeInSeconds.day -
    hours * timeInSeconds.hour -
    minutes * timeInSeconds.minute;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + Number(seconds).toFixed();
  } else {
    seconds = Number(seconds).toFixed();
  }

  return excel ? (
    minus +
      (years ? years + "y " : "") +
      (months ? months + "m " : "") +
      (days ? days + "d " : "") +
      hours +
      ":" +
      minutes +
      (hasSeconds ? ":" + seconds : "") +
      " " +
      milliseconds
  ) : (
    <span style={{ whiteSpace: "nowrap" }}>
      {minus +
        (years ? years + "y " : "") +
        (months ? months + "m " : "") +
        (days ? days + "d " : "") +
        hours +
        ":" +
        minutes +
        (hasSeconds ? ":" + seconds : "")}
      <span style={{ fontSize: "8px" }}>{milliseconds}</span>
    </span>
  );
};

export const toTimeFormatforExcelExport = function (
  sec: string | number,
  hasSeconds = true,
): any {
  const timeInSeconds = {
    minute: 60,
    hour: 60 * 60,
    day: 24 * 60 * 60,
    month: 30.4 * 24 * 60 * 60,
    year: 365 * 24 * 60 * 60,
  };
  if (!sec) return "00:00:00 00";
  let milliseconds = "00";
  const tempMilliseconds = sec.toString().split(".");
  milliseconds =
    tempMilliseconds[1] === undefined
      ? "00"
      : tempMilliseconds[1].length === 1
      ? tempMilliseconds[1].substring(0, 2) + "0"
      : tempMilliseconds[1].substring(0, 2);

  let sec_num: any = null;
  if (typeof sec !== "string") sec = sec.toString();
  sec_num = parseInt(sec, 10); // don't forget the second param
  let years: string | number = Math.floor(sec_num / timeInSeconds.year);
  let months: string | number = Math.floor(
    (sec_num - years * timeInSeconds.year) / timeInSeconds.month,
  );
  let days: string | number = Math.floor(
    (sec_num - years * timeInSeconds.year - months * timeInSeconds.month) /
      timeInSeconds.day,
  );
  let hours: string | number = Math.floor(
    (sec_num -
      years * timeInSeconds.year -
      months * timeInSeconds.month -
      days * timeInSeconds.day) /
      timeInSeconds.hour,
  );
  let minutes: string | number = Math.floor(
    (sec_num -
      years * timeInSeconds.year -
      months * timeInSeconds.month -
      days * timeInSeconds.day -
      hours * timeInSeconds.hour) /
      timeInSeconds.minute,
  );
  let seconds: string | number =
    sec_num -
    years * timeInSeconds.year -
    months * timeInSeconds.month -
    days * timeInSeconds.day -
    hours * timeInSeconds.hour -
    minutes * timeInSeconds.minute;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + Number(seconds).toFixed();
  } else {
    seconds = Number(seconds).toFixed();
  }

  return (
    (years ? years + "y " : "") +
    (months ? months + "m " : "") +
    (days ? days + "d " : "") +
    hours +
    ":" +
    minutes +
    (hasSeconds ? ":" + seconds : "") +
    " " +
    milliseconds
  );
};

export const secondsToTime = function (seconds: number): Date {
  const date = new Date();
  date.setHours(Math.floor(seconds / 3600));
  date.setMinutes(Math.floor((seconds % 3600) / 60));
  date.setSeconds(seconds % 60);
  return date;
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getDatesArray = (fromDate: number, toDate: number): Array<any> => {
  const arr = [];
  for (
    let currentDate = fromDate;
    currentDate < toDate;
    currentDate += 86400000
  ) {
    const tempDate = new Date(currentDate);
    const date = moment(tempDate).format("DD-MM-YYYY");
    arr.push({ name: date, title: date });
  }
  return arr;
};

export const getMonthArray = (fromDate: any, toDate: any): Array<any> => {
  const arr = [];
  let currentDate = fromDate;
  while (true) {
    if (currentDate.getTime() > toDate.getTime()) break;
    const monthAsName = monthNames[currentDate.getMonth()];
    arr.push({ name: monthAsName, title: monthAsName });
    currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  }
  return arr;
};

export const getMonthYearsArray = (fromDate: any, toDate: any): Array<any> => {
  const arr = [];
  let currentDate = fromDate;
  while (true) {
    if (currentDate.getTime() > toDate.getTime()) break;
    const monthAsName =
      monthNames[currentDate.getMonth()] +
      "-" +
      new Date(currentDate).getFullYear();
    arr.push({ name: monthAsName, title: monthAsName });
    currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  }
  return arr;
};

export const toJSONLocalDate = function (date): string {
  function addZ(n): string {
    return (n < 10 ? "0" : "") + n;
  }

  return (
    date.getFullYear() +
    "-" +
    addZ(date.getMonth() + 1) +
    "-" +
    addZ(date.getDate())
  );
};

export const truncateToNCharacters = (str: string, limit: number): string => {
  if (limit < str.length - 1) return `${str.slice(0, limit)}...`;
  else return str;
};

export const toJSONLocal = function (date): string {
  function addZ(n): string {
    return (n < 10 ? "0" : "") + n;
  }

  return (
    date.getFullYear() +
    "-" +
    addZ(date.getMonth() + 1) +
    "-" +
    addZ(date.getDate()) +
    " " +
    addZ(date.getHours()) +
    ":" +
    addZ(date.getMinutes())
  );
};

export const toJSONLocalDateTime = function (date): string {
  function addZ(n): string {
    return (n < 10 ? "0" : "") + n;
  }

  return (
    date.getFullYear() +
    "-" +
    addZ(date.getMonth() + 1) +
    "-" +
    addZ(date.getDate()) +
    " " +
    addZ(date.getHours()) +
    ":" +
    addZ(date.getMinutes()) +
    ":" +
    addZ(date.getSeconds())
  );
};

export const hmsToSecondsOnly = (str): number => {
  const p = str.split(":");
  let s = 0,
    m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return +s;
};

export const blobToFile = (theBlob, fileName): any => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

export const saveData = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  return function (data, fileName) {
    a.href = data;
    a.download = fileName;
    a.click();
  };
})();

export const formatSecondsToTime = (seconds): string => {
  // Hours, minutes and seconds
  const convertSeconds = Math.abs(seconds);
  const hrs = ~~(convertSeconds / 3600);
  const mins = ~~((convertSeconds % 3600) / 60);
  const secs = ~~convertSeconds % 60;

  // Output like "00:01:01" or "04:03:59" or "123:03:59"
  let ret = "";
  ret +=
    (hrs < 10 && hrs > -10 ? "0" : "") +
    hrs +
    ":" +
    (mins < 10 && mins > -10 ? "0" : "");
  ret += "" + mins + ":" + (secs < 10 && secs > -10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

export const formatSecondsToDateTime = (seconds): Date => {
  // Hours, minutes and seconds
  const hrs = ~~(seconds / 3600);
  const mins = ~~((seconds % 3600) / 60);
  const secs = ~~seconds % 60;

  const d = new Date();
  d.setHours(hrs);
  d.setMinutes(mins);
  d.setSeconds(secs);
  return d;
};

export const formatHHMMSStoFullDateTime = (hhmmss): Date => {
  const d = new Date();
  d.setHours(hhmmss.slice(0, 2));
  d.setMinutes(hhmmss.slice(3, 5));
  d.setSeconds(hhmmss.slice(6, 8));
  return d;
};

export const replaceRouteIndecies = (
  path: string,
  values: Array<string | number>,
): string => {
  values.forEach((value) => (path = path.replace(/:[^:\/]+\/?/, `${value}/`)));
  return path.slice(0, -1);
};

export const playAudioEndless = (audio: HTMLAudioElement): HTMLAudioElement => {
  if (audio) {
    audio.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false,
    );

    audio.play();
  }
  return audio;
};

export const getAgentImage = (): string => {
  let userImage = localStorage.getItem("userImage")
    ? localStorage.getItem("userImage")
    : null;

  const gender = require("gender-detection");

  const nameGender = gender.detect(
    localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"),
  );

  if (!userImage) {
    switch (nameGender) {
      case "male":
        userImage = maleAgent;
        break;
      case "female":
        userImage = femaleAgent;
        break;
      default:
        userImage = maleAgent;
        break;
    }
  }
  return userImage;
};

export const exportBlobByUrl = async (blob: any): Promise<any> => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = `Requests-${new Date().toString()}.xlsx`;
  a.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
  }, 0);
};

export const downloadFile = (data, filename, type): void => {
  const blob = new Blob([data], { type: type || "application/octet-stream" });
  const blobURL = window.URL.createObjectURL(blob);

  switch (type) {
    case "pdf":
      const file = new Blob([data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "jfif":
      const image = `<img src='${blobURL}' alt='${filename}' style='max-height:600px'/>`;
      const w = window.open("");
      w.document.write(image);
      break;
    default:
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      tempLink.setAttribute("download", filename);
      if (typeof tempLink.download === "undefined")
        tempLink.setAttribute("target", "_blank");

      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);

      window.URL.revokeObjectURL(blobURL);
      break;
  }
};

export const calculatePercent = (x, y): string => {
  const answer = (x / y) * 100;
  return answer.toFixed(1);
};

export const convertImagetoBase64 = async (path, api): Promise<any> => {
  try {
    const type = FileTypes[path.split(".")[1].toUpperCase()];
    const response = await api(path);
    const data = await response.blob();

    const blob = new Blob([data], {
      type: type || "application/octet-stream",
    });
    const blobURL = window.URL.createObjectURL(blob);

    return blobURL;
  } catch (e) {
    return null;
  }
};

export const camelCaseToCapitalizedWords = (camelCaseString: string): string =>
  camelCaseString
    .replace(/([A-Z])/g, " $1")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const hoursToTimestamps = (hours: number[]): string[] => {
  const startDate = new Date().setHours(0, 0, 0, 0); // set the time to 00:01:00.000
  return hours.map((hour) => {
    const newDate = new Date(startDate);
    newDate.setHours(hour + 4);
    return newDate.toISOString();
  });
};

export const formatBirthDate = (date): string => {
  const dateParts = date.split("-");
  const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  return formattedDate;
};

export const formatDateObjectToString = (date: Date): string => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const isMoreThan24Hours = (date: string): boolean => {
  const tempDate = moment(new Date(date)).format(DATE_TIME_FORMAT);
  const completeDateTime = new Date(tempDate).getTime();
  const currentTime = Date.now();
  return currentTime - completeDateTime > MILLISECONDS_IN_A_DAY;
};
