import i18n from "i18n/config";

export const filterTypes = [
  {
    name: "idCard",
    alias: i18n.t("request_edu_id_card"),
  },
  {
    name: "tempResidence",
    alias: i18n.t("request_edu_temp_residence"),
  },
];

export const initialCustomerDetails = {
  firstName: "",
  lastName: "",
  fatherName: "",
  birthDate: null,
  pin: "",
};
