import { eventSourceActionTypes as actionTypes } from "../Actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  dashboardSseSource: null,
  spycallSseSource: null,
  spycallPhoneNumber: null,
};

const eventSourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_SSESOURCE:
      return updateObject(state, { dashboardSseSource: action.data });
    case actionTypes.SPYCALL_SSESOURCE:
      return updateObject(state, { spycallSseSource: action.data });
    case actionTypes.SPYCALL_PHONE_NUMBER:
      return updateObject(state, {
        spycallPhoneNumber: action.phoneNumber,
      });
    default:
      return state;
  }
};

export default eventSourceReducer;
