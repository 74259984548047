import { styled } from "@material-ui/core";
import { closeBreak } from "Api/configuration";
import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { RequestContextStore } from "Components/Layout/AppLayout";
import Notification from "Notifications/Notification";
import React, { useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { default as BreakActions } from "Store/Actions/Breaks";
import Call from "Store/Actions/Call";
import Cch from "Store/Actions/Cch";
import ChatBox from "Store/Actions/ChatBox";
import Note from "Store/Actions/Note";
import Request from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";
import Topic from "Store/Actions/Topic";

const CancelButton = (): React.ReactElement => {
  const { translate } = useTranslation();
  const softPhoneStore = new SoftPhoneStore(useDispatch());

  const {
    menuType,
    showRegistration,
    conversations,
    activeCallId,
    acwBreakId,
    selectedBreakId,
  } = useSelector(
    ({
      requestReducer,
      chatReducer,
      callReducer,
      breakReducer,
    }: RootStateOrAny) => ({
      menuType: requestReducer.menuType,
      showRegistration: requestReducer.showRegistration,
      conversations: chatReducer.conversations,
      activeCallId: callReducer.activeCallId,
      acwBreakId: callReducer.acwBreakId,
      selectedBreakId: breakReducer.selectedBreakId,
    }),
  );

  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  const request = new Request(useDispatch());
  const call = new Call(useDispatch());
  const chatbox = new ChatBox(useDispatch());
  const topic = new Topic(useDispatch());
  const cch = new Cch(useDispatch());
  const note = new Note(useDispatch());
  const breaks = new BreakActions(useDispatch());

  const requestContextStore: any = useContext(RequestContextStore);
  const requestId = requestContextStore.getRequestId();
  const userId = localStorage.getItem("userID");

  const cleanTotalStates = (): void => {
    note.clearNotesByRequestId(menuType, requestId);
    topic.clearTopicsByRequestId(menuType, requestId);
    cch.clearTasksByRequestId(menuType, requestId);
  };

  const closeRegistration = async (): Promise<void> => {
    if (menuType === menuTypes.CALL && activeCallId !== 0) {
      console.log("removing call from edit");
      call.removeCallFromEdit(requestId);
      cleanTotalStates();
    } else if (conversations[requestId]?.editMode) {
      console.log("removing chat from edit");
      chatbox.removeConversationFromEdit(requestId);
      cleanTotalStates();
    } else if (activeCallId === 0) {
      if (
        !softPhoneState.isCallContinuing &&
        !softPhoneState.isSavedInCall &&
        (localStorage.getItem("autoAcw") === "true" ||
          softPhoneState.willBeSavedAfterHoldCallOver) &&
        parseInt(selectedBreakId) === acwBreakId
      ) {
        softPhoneStore.setSpyCallActive(false);
        const response = await closeBreak(+userId, +acwBreakId, null);
        if (response) {
          breaks.setSelectedBreakId("default");
          breaks.setCloseBreakId("null");
        }
      }
    }

    if (showRegistration) request.setShowRegistration(false);
  };
  const RegistrationPageNotification = (): void => {
    softPhoneState.isSpyCallActive === false &&
      Notification.success(translate("reg_page_save_notfi"));
  };

  return (
    <Button
      onClick={() =>
        handleRegClick(
          RegistrationPageNotification,
          closeRegistration,
          menuType,
          activeCallId,
          acwBreakId,
          selectedBreakId,
          softPhoneState,
        )
      }>
      {translate("cancel")}
    </Button>
  );
};

export default CancelButton;

const Button = styled("div")({
  backgroundColor: "#F5F4F7",
  borderRadius: 24,
  padding: "8px 16px",
  color: "#81808A",
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: "0.04em",
  lineHeight: "140%",
  textTransform: "uppercase",
  cursor: "pointer",
});

export const handleRegClick = (
  RegistrationPageNotification,
  closeRegistration,
  menuType,
  activeCallId,
  acwBreakId,
  selectedBreakId,
  softPhoneState,
) => {
  if (
    menuType === menuTypes.CALL &&
    activeCallId === 0 &&
    selectedBreakId === acwBreakId &&
    softPhoneState.isSpyCallActive === false &&
    softPhoneState.willBeSavedAfterACWCallOver === true
  ) {
    RegistrationPageNotification();
  } else {
    closeRegistration();
  }
};
