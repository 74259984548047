import i18n from "i18n/config";

export const columnList = [
  {
    name: "actions",
    alias: i18n.t("request_table_actions"),
    sortable: false,
  },
  {
    name: "statusDetails",
    alias: i18n.t("request_table_status_details"),
    sortable: false,
  },
  {
    name: "communicationType",
    alias: i18n.t("request_table_communication_type"),
    sortable: false,
  },
  {
    name: "type",
    alias: i18n.t("request_table_type"),
    sortable: false,
  },
  {
    name: "contact",
    alias: i18n.t("request_table_contact"),
    sortable: false,
  },
  {
    name: "number",
    alias: i18n.t("request_table_phone_number"),
    sortable: false,
  },
  {
    name: "agent",
    alias: i18n.t("request_table_agent"),
    sortable: false,
  },
  {
    name: "topics",
    alias: i18n.t("request_table_topics"),
    sortable: false,
  },
  {
    name: "createdDate",
    alias: i18n.t("request_table_created_date"),
    sortable: false,
  },
  {
    name: "startDate",
    alias: i18n.t("request_table_start_date"),
    sortable: false,
  },
  {
    name: "completeDate",
    alias: i18n.t("request_table_complete_date"),
    sortable: false,
  },
  {
    name: "durationTime",
    alias: i18n.t("request_table_duration_time"),
    sortable: false,
  },
  {
    name: "talkDuration",
    alias: i18n.t("request_table_talk_duration"),
    sortable: false,
  },
  {
    name: "holdTime",
    alias: i18n.t("request_table_hold_duration"),
    sortable: false,
  },
  {
    name: "queueName",
    alias: i18n.t("request_table_queue_name"),
    sortable: false,
  },
  {
    name: "queueWaitTime",
    alias: i18n.t("request_table_queue_waiting_time"),
    sortable: false,
  },
  {
    name: "enterQueueDate",
    alias: i18n.t("request_table_enter_queue_date"),
    sortable: false,
  },
  {
    name: "surveyPoint",
    alias: i18n.t("request_table_survey_point"),
    sortable: false,
  },
  {
    name: "id",
    alias: i18n.t("request_table_request_id"),
    sortable: false,
  },
  {
    name: "omniChannelId",
    alias: i18n.t("request_table_omnichannel_id"),
    sortable: false,
  },
];

export const selectColumn = {
  name: "select",
  alias: "",
  sortable: false,
};

export const communicationTypeData = [
  {
    value: "FB",
    name: "FB",
  },
  {
    value: "INSTAGRAM",
    name: "INSTAGRAM",
  },
  {
    value: "WHATSAPP",
    name: "WHATSAPP",
  },
  {
    value: "TELEGRAM",
    name: "TELEGRAM",
  },
  {
    value: "WEBCHAT",
    name: "WEBCHAT",
  },
  {
    value: "SIPPHONE",
    name: "SIPPHONE",
  },
];

export const type = [
  {
    value: "in",
    name: "in",
  },
  {
    value: "out",
    name: "out",
  },
  {
    value: "ivr",
    name: "ivr",
  },
  {
    value: "abandon",
    name: "abandon",
  },
  {
    value: "unassigned",
    name: "unassigned",
  },
];

export const defaultType = ["in", "out", "ivr", "abandon", null];

export const surveyPoint = [
  {
    value: "1",
    name: "1",
  },
  {
    value: "2",
    name: "2",
  },
  {
    value: "3",
    name: "3",
  },
  {
    value: "4",
    name: "4",
  },
  {
    value: "5",
    name: "5",
  },
];
