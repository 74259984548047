import { styled } from "@material-ui/core";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "85%",
  textAlign: "left",
  fontSize: 14,
  padding: 40,
});

export const CloseButtonContainer = styled("div")({
  marginTop: "auto",
});
