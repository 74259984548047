import { styled } from "@material-ui/core";

export const Container = styled("div")({
  backgroundColor: "white",
  display: "flex",
  alignItems: "end",
  marginTop: 25,
});

export const Label = styled("span")({
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginBottom: 10,
  marginTop: 10,
});
