import axios from "Api/axios";
import WebConf, { defaultRoute } from "Config/WebConf";

export const getCustomDetailsByIdCard = (
  pin: string,
  birthDate: string,
): Promise<any> => {
  return axios
    .get(`${defaultRoute}${WebConf.iamas.getCustomDetailsByIdCard}/${pin}`, {
      params: { birthDate },
    })
    .then((response) => response);
};

export const getCustomDetailsByResidencePermit = (pin: string): Promise<any> =>
  axios
    .get(
      `${defaultRoute}${WebConf.iamas.getCustomDetailsByResidencePermit}/${pin}`,
    )
    .then((response) => response);
