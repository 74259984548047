import { Button, DialogActions } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Drawer from "Components/Drawer/Drawer";
import LoadingComponent from "Components/Loading/LoadingComponent";
import React from "react";

import ActionsButton from "./components/ActionsButton";
import CustomerDetails from "./components/CustomerDetails";
import Filter from "./components/Filter";
import Tabs from "./components/Tabs";
import { useEduIntegration } from "./hooks/useEduIntegration";
import { EduIntegrationProps } from "./interfaces";
import { CloseButtonContainer, Container } from "./styles/EduIntegration";

const EduIntegration = ({
  open,
  setOpen,
}: EduIntegrationProps): React.ReactElement => {
  const {
    selectedTab,
    setSelectedTab,
    pin,
    isLoading,
    disableSearchButton,
    customerDetails,
    setCustomerDetails,
    setIsLoading,
    setPin,
    birthDate,
    setBirthDate,
    handleCancelButton,
    handleConfirmButton,
  } = useEduIntegration();

  const { translate } = useTranslation();

  return (
    <Drawer
      open={open}
      handleClose={() => {
        setOpen(false);
        handleCancelButton();
      }}>
      <Container>
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <Filter
          selectedTab={selectedTab}
          pin={pin}
          disableSearchButton={disableSearchButton}
          setPin={setPin}
          birthDate={birthDate}
          setCustomerDetails={setCustomerDetails}
          setBirthDate={setBirthDate}
          setIsLoading={setIsLoading}
        />
        <CustomerDetails
          customerDetails={customerDetails}
          selectedTab={selectedTab}
        />
        <ActionsButton
          customerDetails={customerDetails}
          handleCancelButton={handleCancelButton}
          handleConfirmButton={handleConfirmButton}
        />
        <CloseButtonContainer>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                handleCancelButton();
              }}>
              {translate("close")}
            </Button>
          </DialogActions>
        </CloseButtonContainer>
      </Container>
      {isLoading ? <LoadingComponent /> : null}
    </Drawer>
  );
};

export default EduIntegration;

EduIntegration.displayName = "EduIntegration";
