/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import menuTypes from "Assets/constants/menuTypes";
import { initialCustomerDetails } from "Components/Crm/EduIntegrationSearch/constants";
import { getCustomField } from "Components/Request/LeftPanel/hooks/useCrm";

import { crmActions as actionTypes } from "../Actions/actionTypes";
import {
  getRequestStates,
  updateObject,
  updateTotalState,
  deepClone,
} from "../utility";

const initialState = {
  [menuTypes.CALL]: {},
  [menuTypes.CHAT]: {},
  defaultFields: null,
  customerSearchPanelIsShown: false,
  copyAttachments: [],
  copyAnswer: "",
  copyIconOnclick: false,
  clickReset: null,
  customerCif: null,
  accountNumber: null,
  eduIntegration: initialCustomerDetails,
  eduConfirmButton: false,
};

export const defaultStates = {
  companies: [],
  formFields: [],
  identifiers: null,
  checkValidationGroup: null,
  formIsValid: false,
  profileImage: null,
  customerId: null,
};

const setOptionsByDictionaryId = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  const updatedFieldValueList = [...requestSatates.formFields];
  updatedFieldValueList.map((field, index) => {
    if (field.dictionaryId === action.dictionaryId) {
      updatedFieldValueList[index].options = action.options;
    }
  });
  requestSatates = updateObject(requestSatates, {
    formFields: updatedFieldValueList,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setCustomerData = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, {
    formFields: action.formFields,
    identifiers: action.identifiers,
    customerId: action.customerId,
    profileImage: action.photo,
    companies: action.companies,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const removeLastField = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const updatedFieldValueList = [...requestSatates.formFields];

  let fieldId = null;
  updatedFieldValueList.forEach((field) => {
    if (!fieldId && field.customerType == action.customerType && field.custom) {
      fieldId = field.id;
    }
  });

  const filteredFields = updatedFieldValueList.filter(
    (field) => field.id != fieldId,
  );

  requestSatates = updateObject(requestSatates, { formFields: filteredFields });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const addCustomField = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const updatedFieldValueList = [...requestSatates.formFields];
  updatedFieldValueList.push(getCustomField(action.customerType));

  requestSatates = updateObject(requestSatates, {
    formFields: updatedFieldValueList,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setIdentifierValue = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const updatedIdentifiers = [...requestSatates.identifiers];

  updatedIdentifiers.map((field, index) => {
    if (field.channelType === action.id) {
      updatedIdentifiers[index].identifier = action.value;
    }
  });

  requestSatates = updateObject(requestSatates, {
    identifiers: updatedIdentifiers,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setFieldValue = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const updatedFieldValueList = [...requestStates.formFields];

  updatedFieldValueList.map((field, index) => {
    if (action.id) {
      if (field.id == action.id) {
        updatedFieldValueList[index].fieldValue = action.value;
      }
    } else {
      if (field.fieldName == action.fieldName) {
        updatedFieldValueList[index].fieldValue = action.value;
      }
    }
  });

  requestStates = updateObject(requestStates, {
    formFields: updatedFieldValueList,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setFieldPlaceHolder = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const updatedFieldValueList = [...requestSatates.formFields];

  updatedFieldValueList.map((field, index) => {
    if (field.id == action.id)
      updatedFieldValueList[index].fieldName = action.value;
  });

  requestSatates = updateObject(requestSatates, {
    formFields: updatedFieldValueList,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setValuesToFormFieldsByCrmTableData = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const updatedFieldValueList = [...requestSatates.formFields];

  updatedFieldValueList.map((field, index) => {
    if (action.data[field.fieldName]) {
      updatedFieldValueList[index].fieldValue = action.data[field.fieldName];
    }
  });

  requestSatates = updateObject(requestSatates, {
    formFields: updatedFieldValueList,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setCompanies = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    companies: action.companies,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const addCompany = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, { company: action.company });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setFormFields = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    formFields: action.formFields,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setFormIsValid = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    formIsValid: action.formIsValid,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setCheckValidationGroup = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    checkValidationGroup: action.checkValidationGroup,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setCustomerId = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    customerId: action.customerId,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setProfileImage = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    profileImage: action.profileImage,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setIdentifiers = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, {
    identifiers: action.identifiers,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const clearAllData = (state, action) => {
  const updatedMenuStates = deepClone(state[action.menuType]);

  updatedMenuStates[action.requestId] = defaultStates;

  return updateObject(state, { [action.menuType]: updatedMenuStates });
};

const setDefaultFields = (state, action) => {
  return updateObject(state, { defaultFields: action.defaultFields });
};

const showCustomerSearch = (state, action) => {
  return updateObject(state, {
    customerSearchPanelIsShown: action.customerSearchPanelIsShown,
  });
};

const removeEndedConversation = (state, action) => {
  const updatedMenuStates = deepClone(state[action.menuType]);

  delete updatedMenuStates[action.requestId];

  return updateObject(state, { [action.menuType]: updatedMenuStates });
};

const crmReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPANIES:
      return setCompanies(state, action);

    case actionTypes.ADD_COMPANY:
      return addCompany(state, action);

    case actionTypes.SET_FORM_FIELDS:
      return setFormFields(state, action);

    case actionTypes.SET_CHECK_VALIDATION_GROUP:
      return setCheckValidationGroup(state, action);

    case actionTypes.SET_FORM_IS_VALID:
      return setFormIsValid(state, action);

    case actionTypes.SET_OPTIONS_BY_DICTIONARY_ID:
      return setOptionsByDictionaryId(state, action);

    case actionTypes.ADD_CUSTOM_FIELD:
      return addCustomField(state, action);

    case actionTypes.REMOVE_LAST_FIELD:
      return removeLastField(state, action);

    case actionTypes.SET_FIELD_VALUE:
      return setFieldValue(state, action);

    case actionTypes.SET_FIELD_PLACEHOLDER:
      return setFieldPlaceHolder(state, action);

    case actionTypes.SET_IDENTIFIER_VALUE:
      return setIdentifierValue(state, action);

    case actionTypes.SET_CUSTOMER_ID:
      return setCustomerId(state, action);

    case actionTypes.SET_PROFILE_IMAGE:
      return setProfileImage(state, action);

    case actionTypes.SET_IDENTIFIERS:
      return setIdentifiers(state, action);

    case actionTypes.SET_CUSTOMER_DATA:
      return setCustomerData(state, action);

    case actionTypes.CLICK_RESET:
      return { ...state, clickReset: action.clickReset };

    case actionTypes.CUSTOMER_CIF:
      return { ...state, customerCif: action.customerCif };

    case actionTypes.ACCOUNT_NUMBER:
      return { ...state, accountNumber: action.accountNumber };

    case actionTypes.SET_DEFAULT_FIELDS:
      return setDefaultFields(state, action);

    case actionTypes.SHOW_CUSTOMER_SEARCH:
      return showCustomerSearch(state, action);

    case actionTypes.SET_VALUE_TO_FROM_FIELDS_BY_CRM_TABLE_DATA:
      return setValuesToFormFieldsByCrmTableData(state, action);

    case actionTypes.COPY_ATTACHMENTS:
      return { ...state, copyAttachments: action.attachmentsData };

    case actionTypes.COPY_ANSWER:
      return { ...state, copyAnswer: action.copyAnswer };

    case actionTypes.EDU_INTEGRATION_DATA:
      return { ...state, eduIntegration: action.eduIntegration };

    case actionTypes.EDU_CONFIRM_BUTTON:
      return { ...state, eduConfirmButton: action.eduConfirmButton };

    case actionTypes.COPY_ICON_ONCLICK:
      return { ...state, copyIconOnclick: action.copyIconOnclick };

    case actionTypes.CLEAR_ALL_DATA:
      return clearAllData(state, action);

    case actionTypes.REMOVE_ENDED_CONVERSATION:
      return removeEndedConversation(state, action);
    default:
      return state;
  }
};

export default crmReducer;
